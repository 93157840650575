import React, { useState } from 'react';

export default function SelectYear({ setYear }) {
    const [setselectedYear, setSetselectedYear] = useState(2023);
    return (
        <div className="flex h-screen">
            <div className="m-auto">
                <h1 className="uppercase font-bold text-lg m-auto mb-5">Select year:</h1>
                <select
                    className="w-full border-[2px] border-black bg-white rounded-md p-2 "
                    value={setselectedYear}
                    onChange={(e) => setSetselectedYear(e.target.value)}
                >
                    <option className="checked:bg-black" value={2020}>
                        2020
                    </option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                </select>
                <button
                    onClick={() => {
                        setYear(setselectedYear);
                    }}
                    className="w-full mt-10"
                >
                    Next Step
                </button>
            </div>
        </div>
    );
}
