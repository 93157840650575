import { useGoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import { CredentialContext } from '../context/CredentialProvider';

const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly';

export default function Login() {
    const { setCredentials } = useContext(CredentialContext);
    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => setCredentials(tokenResponse),
        scope: SCOPES,
    });

    return (
        <div className="w-full h-screen flex justify-center">
            <button className="m-auto" onClick={() => login()}>
                Sign in with Google 🚀
            </button>
        </div>
    );
}
