import React, { useContext } from 'react';
import { CredentialContext } from '../context/CredentialProvider';

export default function SelectCalendars({ availableCalendars, setEvents, year }) {
    const { credentials } = useContext(CredentialContext);

    return (
        <div className="flex justify-center h-screen">
            <div className="m-auto">
                <h1 className="uppercase font-bold text-lg m-auto mb-5">Select calendars:</h1>
                <ul>
                    {availableCalendars.map((calendar) => (
                        <li key={calendar.id}>
                            <input type="checkbox" className="mr-5" value={calendar.id} />
                            {calendar.summary}
                        </li>
                    ))}
                </ul>
                <button
                    onClick={() => {
                        const newSelectedCalendars = availableCalendars.filter((calendar) => {
                            return document.querySelector(`input[value="${calendar.id}"]`).checked;
                        });
                        const calendarIds = newSelectedCalendars.map((calendar) => calendar.id);
                        let calendarEvents = [];
                        Promise.all(
                            calendarIds.map(async (calendarId) => {
                                const response = await fetch(
                                    `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?maxResults=2500&orderBy=startTime&singleEvents=true&timeMax=${
                                        year + 1
                                    }-01-01T10%3A00%3A00-00%3A00&timeMin=${year}-01-01T10%3A00%3A00-00%3A00&prettyPrint=true&fields=items(summary%2C%20start%2C%20end%2C%20colorId)`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${credentials.access_token}`,
                                        },
                                    }
                                );

                                if (!response.ok) {
                                    return [];
                                }

                                const data = await response.json();
                                calendarEvents = [...calendarEvents, ...data.items];
                                return calendarEvents;
                            })
                        ).then(() => {
                            setEvents(calendarEvents);
                        });
                    }}
                    className="w-full mt-10"
                >
                    Next
                </button>
            </div>
        </div>
    );
}
