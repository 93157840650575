import { G, Image, Page, Rect, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react';

export default function Day({ dayKey, dayEvents, images }) {
    const startHour = 0;
    const endHour = 24;
    const hoursPerDay = endHour - startHour;

    const smallEvent = (event, index, color) => {
        return (
            <View
                style={[
                    {
                        top: `${
                            ((event.startDate.getHours() + event.startDate.getMinutes() / 60 - startHour) /
                                hoursPerDay) *
                                100 -
                            0.05
                        }%`,
                        height: `${
                            (100 / hoursPerDay) * (event.duration / 3600000) + (event.duration === 900000 ? 0.5 : 0.3)
                        }%`,
                        width: '85%',
                        marginLeft: 70,
                        position: 'absolute',
                        borderRadius: 5,
                        border: '2px solid black',
                        backgroundColor: color.background,
                    },
                ]}
                key={index}
            >
                <View
                    style={{
                        padding: '0 5',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <Text
                        style={{
                            fontSize: event.duration <= 900000 ? 6 : event.duration >= 2700000 ? 13 : 10,
                            color: color.foreground,
                        }}
                    >
                        {event.summary}
                    </Text>
                    <Text
                        style={{
                            fontSize: event.duration <= 900000 ? 6 : event.duration >= 2700000 ? 13 : 10,
                            color: color.foreground,
                            margin: 'auto 0',
                            marginLeft: 5,
                        }}
                    >
                        {event.eventTime}
                    </Text>
                </View>
            </View>
        );
    };

    const multiDayEvent = (event, index, countOfMultiDayEvents, color) => {
        return (
            <View
                style={[
                    {
                        top: `0%`,
                        height: `${100 / hoursPerDay}%`,
                        width: `100%`,
                        position: 'absolute',
                        padding: '0 30',
                    },
                ]}
                key={index}
            >
                <View
                    style={[
                        {
                            top: `0%`,
                            height: `100%`,
                            width: `${100 / countOfMultiDayEvents}%`,
                            marginLeft: `${(100 / countOfMultiDayEvents) * index + 5}%`,
                            borderRadius: 5,
                        },
                    ]}
                >
                    <View
                        style={[
                            {
                                height: `100%`,
                                width: '100%',
                                position: 'absolute',
                                border: '2px solid black',
                                backgroundColor: color.background,
                                transform: 'skew(-20deg)',
                            },
                        ]}
                        key={index}
                    ></View>
                    <View
                        style={{
                            padding: '2 5',
                            display: 'flex',
                            flexDirection: countOfMultiDayEvents > 1 ? 'column' : 'row',
                            width: '100%',
                            margin: 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={{
                                fontSize: countOfMultiDayEvents <= 1 ? 13 : 10,
                                color: color.foreground,
                                ...(countOfMultiDayEvents > 1 && { margin: 'auto' }),
                            }}
                        >
                            {event.summary}
                        </Text>
                        {!event.isFullDay && (
                            <Text
                                style={{
                                    fontSize: countOfMultiDayEvents <= 1 ? 13 : 7,
                                    color: color.foreground,
                                    margin: countOfMultiDayEvents > 1 ? 'auto' : 'auto 0',
                                    ...(countOfMultiDayEvents === 1 && { marginLeft: 5 }),
                                }}
                            >
                                {event.eventTime}
                            </Text>
                        )}
                    </View>
                </View>
            </View>
        );
    };

    const normalEvent = (event, index, color) => {
        return (
            <View
                style={[
                    {
                        top: `${
                            ((event.startDate.getHours() + event.startDate.getMinutes() / 60 - startHour) /
                                hoursPerDay) *
                                100 -
                            0.05
                        }%`,
                        height: `${(100 / hoursPerDay) * (event.duration / 3600000) - 0.3}%`,
                        width: '85%',
                        marginLeft: 70,
                        position: 'absolute',
                        borderRadius: 5,
                        border: '2px solid black',
                        backgroundColor: color.background,
                    },
                ]}
                key={index}
            >
                <Box
                    style={[
                        {
                            height: `100%`,
                            width: '70%',
                            position: 'absolute',
                            borderRadius: 5,
                        },
                    ]}
                    key={index}
                ></Box>
                <View style={{ padding: '2 5' }}>
                    <Text style={{ fontSize: 13, color: color.foreground }}>{event.summary}</Text>
                    <Text style={{ fontSize: 10, color: color.foreground }}>{event.eventTime}</Text>
                </View>
            </View>
        );
    };

    return (
        <Page size="A4" style={{ fontFamily: 'Short Stack', padding: 20 }} key={dayKey}>
            <View style={{ height: '100%' }}>
                <Text style={{ fontFamily: 'Short Stack', marginBottom: 20 }}>{getDateString(dayKey)}</Text>

                <View style={{ height: '100%', position: 'relative' }}>
                    {/* create for each hour per day a line and time text */}
                    {[...Array(hoursPerDay)].map((_, index) => (
                        <>
                            <Text
                                style={{
                                    position: 'absolute',
                                    top: `${(index / hoursPerDay) * 100 - 0.8}%`,
                                    left: 0,
                                    fontSize: 10,
                                    color: '#70757a',
                                    backgroundColor: 'white',
                                    zIndex: 100,
                                }}
                                key={index}
                            >
                                {numberToTime(index + startHour)}
                            </Text>
                            <View
                                style={{
                                    height: '0.5px',
                                    backgroundColor: '#70757a',
                                    position: 'absolute',
                                    top: `${(index / hoursPerDay) * 100}%`,
                                    width: '100%',
                                    marginLeft: 40,
                                }}
                                key={index}
                            />
                        </>
                    ))}
                    {dayEvents &&
                        dayEvents.map((event, index) => {
                            const color = getColor(event.colorId || 0);
                            return event.isMultiDay
                                ? multiDayEvent(event, index, dayEvents.filter((x) => x.isMultiDay).length, color)
                                : event.duration <= 2700000
                                ? smallEvent(event, index, color)
                                : normalEvent(event, index, color);
                        })}

                    {images.map((image) => {
                        const lastModified = new Date(image.lastModified);
                        return (
                            <Image
                                key={image.name}
                                src={URL.createObjectURL(image)}
                                style={{
                                    width: '100px',
                                    position: 'absolute',
                                    right: 20,
                                    top: `${
                                        ((lastModified.getHours() + lastModified.getMinutes() / 60 - startHour) /
                                            hoursPerDay) *
                                            100 -
                                        0.05
                                    }%`,
                                    backgroundColor: 'black',
                                    borderRadius: 5,
                                    height: 'auto',
                                }}
                            />
                        );
                    })}
                </View>
            </View>
        </Page>
    );
}

const Box = ({ style }) => {
    return (
        <Svg style={style} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 100 500">
            <G>
                <Rect id="svg_1" height="100%" width="90%" /* fill="rgb(196, 235, 255)" */ rx="2" ry="50" />
            </G>
        </Svg>
    );
};

const numberToTime = (number) => {
    const date = new Date();
    date.setHours(number);
    date.setMinutes(0);
    return date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
    });
};

const getDateString = (date) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleDateString('en-GB', {
        weekday: 'long',
    })}, ${dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
    })}. ${dateObj.toLocaleDateString('en-GB', {
        month: 'long',
    })} ${dateObj.getFullYear()}`;
};

const getColor = (colorId) => {
    switch (colorId) {
        case '1':
            return { background: '#a4bdfc', foreground: '#1d1d1d' };
        case '2':
            return { background: '#7ae7bf', foreground: '#1d1d1d' };
        case '3':
            return { background: '#dbadff', foreground: 'white' };

        case '4':
            return { background: '#ff887c', foreground: 'white' };

        case '5':
            return { background: '#fbd75b', foreground: '#1d1d1d' };

        case '6':
            return { background: '#ffb878', foreground: '#1d1d1d' };

        case '7':
            return { background: '#46d6db', foreground: 'white' };

        case '8':
            return { background: '#e1e1e1', foreground: '#1d1d1d' };

        case '9':
            return { background: '#5484ed', foreground: 'white' };

        case '10':
            return { background: '#51b749', foreground: 'white' };
        case '11':
            return { background: '#dc2127', foreground: 'white' };
        default:
            return { background: 'white', foreground: '#1d1d1d' };
    }
};
