import React, { useEffect, useState } from 'react';
import GoogleCalendar from './GoogleCalendar';
import { CredentialContext } from './context/CredentialProvider';
import Login from './pages/Login';
import SelectCalendars from './pages/SelectCalendars';
import SelectYear from './pages/SelectYear';
import UploadImages from './pages/UploadImages';

const App = () => {
    const [credentials, setCredentials] = useState();
    const [availableCalendars, setAvailableCalendars] = useState([]);
    const [events, setEvents] = useState([]);
    const [year, setYear] = useState(null);
    const [images, setImages] = useState(null);

    useEffect(() => {
        if (credentials?.access_token) {
            fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
                headers: {
                    Authorization: `Bearer ${credentials.access_token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setAvailableCalendars(data.items);
                });
        }
    }, [credentials]);

    return (
        <CredentialContext.Provider value={{ credentials, setCredentials }}>
            {events.length > 0 && images !== null ? (
                <GoogleCalendar events={events} year={year} images={images} />
            ) : events.length > 0 ? (
                <UploadImages setImages={setImages} />
            ) : availableCalendars.length > 0 ? (
                <SelectCalendars availableCalendars={availableCalendars} setEvents={setEvents} year={year} />
            ) : year ? (
                <Login />
            ) : (
                <SelectYear setYear={setYear} />
            )}
        </CredentialContext.Provider>
    );
};

export default App;
