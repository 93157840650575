import { BlobProvider, Document, Font, PDFViewer } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import Day from './components/Day';

export default function GoogleCalendar({ events, year, images }) {
    const [calendarData, setCalendarData] = useState([]);

    Font.register({
        family: 'Short Stack',
        src: 'https://fonts.gstatic.com/s/shortstack/v10/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf',
    });

    Font.registerEmojiSource({
        format: 'png',
        url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
    });

    useEffect(() => {
        const sortedCalendarData = getDaysOfYear(year);

        events.map((event) => {
            if (event.start.date) {
                // it is a full day event
                const startDate = new Date(event.start.date);
                const endDate = new Date(event.end.date);

                endDate.setDate(endDate.getDate() - 1);

                // create for each day a new event
                const days = Math.ceil((endDate - startDate) / 86400000);
                for (let i = 0; i <= days; i++) {
                    const newDayKey = new Date(startDate.getTime() + i * 86400000).toDateString();

                    const newEvent = {
                        summary: event.summary,
                        eventTime: getEventTime(false, startDate, endDate),
                        colorId: event.colorId,
                        startDate,
                        endDate,
                        newDayKey,
                        isMultiDay: true,
                        isFullDay: true,
                        duration: 1800001, // needs to be more than 30 minutes
                    };
                    if (sortedCalendarData[newDayKey]) {
                        sortedCalendarData[newDayKey].unshift(newEvent);
                    }
                }
                return null;
            }

            const startDate = new Date(event.start.dateTime);
            const endDate = new Date(event.end.dateTime);
            const dayKey = startDate.toDateString();

            sortedCalendarData[dayKey]?.push({
                summary: event.summary,
                eventTime: getEventTime(false, startDate, endDate),
                colorId: event.colorId,
                startDate,
                endDate,
                dayKey,
                isMultiDay: false,
                isFullDay: false,
                duration: endDate - startDate,
            });
        });

        return setCalendarData(sortedCalendarData);
    }, []);

    const getEventTime = (isMultiDay, startDate, endDate) => {
        return isMultiDay
            ? `${startDate.toLocaleDateString('en-GB')} - ${endDate.toLocaleDateString('en-GB')}`
            : `${startDate.toLocaleTimeString('de-CH', {
                  hour: '2-digit',
                  minute: '2-digit',
              })} - ${endDate.toLocaleTimeString('de-CH', {
                  hour: '2-digit',
                  minute: '2-digit',
              })}`;
    };

    return (
        <div>
            {window.innerWidth <= 800 ? (
                <div className="w-full h-screen flex justify-center">
                    <BlobProvider document={<MyDocument calendarData={calendarData} images={images} />}>
                        {({ blob, url, loading, error }) => {
                            if (loading || url === null || blob === null) return 'Loading document...';
                            if (error) return `Error downloading document: ${error.message}`;
                            return (
                                <div className="m-auto border-black border-[2px] rounded-md p-5">
                                    <a href={url}>{url}</a>
                                </div>
                            );
                        }}
                    </BlobProvider>
                </div>
            ) : (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                    <MyDocument calendarData={calendarData} images={images} />
                </PDFViewer>
            )}
        </div>
    );
}

const MyDocument = ({ calendarData, images }) => (
    <Document>
        {Object.keys(calendarData)
            .sort((a, b) => new Date(a) - new Date(b))
            .map((dayKey) => {
                const dayEvents = calendarData[dayKey];

                return (
                    <Day
                        key={dayKey}
                        dayKey={dayKey}
                        dayEvents={dayEvents}
                        images={images.filter((image) => new Date(image.lastModified).toDateString() === dayKey)}
                    />
                );
            })}
    </Document>
);

const getDaysOfYear = (year) => {
    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);

    const daysOfYear = [];
    for (let date = start; date <= end; date.setUTCDate(date.getUTCDate() + 1)) {
        daysOfYear[new Date(date).toDateString()] = [];
    }

    return daysOfYear;
};
