import React, { useState } from 'react';

export default function UploadImages({ setImages }) {
    const [newImages, setNewImages] = useState([]);
    return (
        <div className="w-screen h-screen flex">
            <div className="m-auto flex flex-col">
                <div>
                    <input
                        type="file"
                        className="file:bg-white file:text-black file:border-black file:border-[2px] file:rounded-md file:px-5 file:py-2 w-fit text-xs"
                        multiple
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => {
                            const files = event.target.files;
                            setNewImages(Array.from(files));
                        }}
                    />
                </div>
                <button className="mt-20" onClick={() => setImages(newImages)}>
                    Generate✨
                </button>
            </div>
        </div>
    );
}
